import React from 'react'
import { graphql } from 'gatsby'
import { View } from 'react-native-animatable'
import {
  PolicyPageItemProps,
  PolicyPageLinkList,
} from '@src/components/PolicyPageLinkList'

interface SitePolicyPageProps {
  data: {
    policies: {
      edges: {
        node: PolicyPageItemProps
      }[]
    }
  }
}

const SitePolicyPage: React.SFC<SitePolicyPageProps> = (props) => (
  <View animation="fadeIn">
    <PolicyPageLinkList policies={props.data.policies} />
  </View>
)

export default SitePolicyPage

export const pageQuery = graphql`
  query SitePolicyQuery($language: String!) {
    ...PolicyPageListFragment
    ...FooterFragment
  }
  # query SitePolicyQuery(
  #   $language: String!
  # ) {
  #   pages: allSitePage(
  #     filter: {
  #       componentPath: { regex: "/LegalPageTemplate.tsx$/" }
  #       context: {
  #         language: { eq: $language }
  #       }
  #     }
  #   ) {
  #     edges {
  #       node {
  #         path
  #         component
  #         componentPath
  #         internalComponentName
  #         jsonName
  #         context {
  #           basePath
  #           title
  #           description
  #           keywords
  #           language
  #         }
  #         id
  #       }
  #     }
  #   }
  # }
`
